import Vue from "vue";
import {
  isColonial,
  checkIfIsAtlasCompany,
  hasSeacoast
} from "@/forms/utils/index";
import {
  IsYear,
  validateAtlasRoofYear,
  validateColonialRoofYear
} from "@/helpers/validateRoofYear";
import { quoteMapState } from "@/store/modules/quote";
import { IWindIncluded } from "@/store/modules/quote/types";
export default Vue.extend({
  data: function() {
    return {
      needsApproval: false,
      messages: [] as string[]
    };
  },
  methods: {
    checkYearOfRoof(
      yearOfRoof: number,
      yearBuild: number,
      roofMaterial: string,
      companyNumbers: number[],
      formType?: string,
      windIncluded?: IWindIncluded
    ): {
      success: boolean;
      message: string;
      type: string;
    } {
      if (!IsYear(yearOfRoof.toString())) {
        return {
          success: false,
          message: "Valid Roof Installation Year is required",
          type: "invalid"
        };
      }
      if (yearOfRoof && yearBuild && yearOfRoof < yearBuild) {
        this.needsApproval = false;
        return {
          success: false,
          message: "Error – Roof Year Prior to Year Built",
          type: "invalid"
        };
      }
      const currentYear = new Date().getFullYear();
      const roofAge = currentYear - yearOfRoof;

      if (isColonial(companyNumbers) && roofMaterial) {
        const options = {
          roofAge,
          isSeacoast: hasSeacoast(companyNumbers),
          roofMaterial,
          agentCode: this.editing.agentCode,
          windIncluded,
          formType,
          yearOfRoof: this.editing.yearOfRoof,
          yearBuild: this.editing.yearBuild
        };
        const response = validateColonialRoofYear(options);

        if (response?.type === "call") {
          // if (this.editing.approvalCode) {
          //   response.message = "";
          // }
          if (response.reason) {
            this.messages.push(response.reason);
          }
          this.needsApproval = true;
        } else {
          this.needsApproval = false;
        }
        return response;
      }

      if (checkIfIsAtlasCompany(companyNumbers)) {
        const response = validateAtlasRoofYear(15, yearOfRoof, roofMaterial);
        if (response?.type === "call") {
          if (response.reason) {
            this.messages.push(response.reason);
          }
          this.needsApproval = true;
          if (this.editing.approvalCode) {
            response.message = "";
          }
        } else {
          this.needsApproval = false;
        }
        return response;
      }

      return {
        success: false,
        message: "",
        type: ""
      };
    }
  },
  computed: {
    ...quoteMapState(["editing"])
  }
});
