
import Vue, { PropType } from "vue";
import { getPathAPI } from "@/helpers";
import { quoteMapState } from "@/store/modules/quote";
import SingleEndorsement from "./SingleEndorsement.vue";
import { IEndorsement } from "@/store/modules/endorsements/types";

interface IData {
  error: string;
  loading: boolean;
  output: any;
  optionsExample: any[];
  expanded: boolean;
  description: string;
  disableDoneButton: boolean;
  endorsementErrorMap: Record<string, boolean>;
}

export default Vue.extend({
  name: "endorsementsEditor",
  components: {
    SingleEndorsement
  },
  props: {
    options: {
      type: Array as PropType<IEndorsement[]>,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data(): IData {
    return {
      expanded: false,
      error: "",
      loading: false,
      output: {},
      disableDoneButton: false,
      endorsementErrorMap: {},
      optionsExample: [
        {
          selected: false,
          name: "",
          ref: "",
          editable: true,
          showEdits: false,
          values: [
            {
              label: "Type",
              value: "",
              ref: "type"
            },
            {
              label: "",
              value: "",
              ref: ""
            }
          ]
        },
        {
          selected: false,
          name: "",
          ref: "",
          editable: true,
          showEdits: false,
          values: [
            {
              label: "",
              value: "",
              ref: ""
            }
          ]
        },
        {
          selected: false,
          name: "",
          ref: "",
          editable: false,
          showEdits: false
        }
      ],
      description: ""
    };
  },
  methods: {
    showModal(description: string) {
      this.description = description;
      this.$modal.show("endorsementsModal");
    },
    handleEndorsementValuesHasErrors($event: any) {
      this.checkEndorsementErrors($event);
    },
    handleValidation(hasError: boolean) {
      this.checkEndorsementErrors(hasError);
    },
    handleCheckboxChange(option: string, value: boolean) {
      this.$emit("handleChangeCheckBox", option, value);
    },
    inputValueChanged(value: any, option: any, hasError: boolean) {
      this.$emit("inputValueChanged", value, option);
      this.checkEndorsementErrors(hasError);
    },
    inputValueChangedCustom($event: any, option: any, hasError: boolean) {
      this.$emit("inputValueChanged", $event, option);
      this.checkEndorsementErrors(hasError);
    },
    checkEndorsementErrors(hasError: boolean): void {
      this.$emit("endorsementHasErrors", hasError);
    },
    downloadFile(item: any) {
      const accessToken = localStorage.getItem("access");
      const baseUrl = getPathAPI();
      const element = document.createElement("a");
      element.href = `${baseUrl}/file/view/${item._id}?accessToken=${accessToken}`;
      element.download = "";
      element.target = "_blank";
      element.click();
    }
  },
  computed: {
    ...quoteMapState(["editing"]),
    territoryInfo(): any {
      return this.editing.territoryData.territoryInfo;
    },
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    },
    selectedOptionalEndorsements(): number {
      const formNumbers =
        this.editing && this.editing.endorsements
          ? this.editing.endorsements.map((endo: any) => {
              return endo.formNumber;
            })
          : [];
      const selected = this.options.filter((opt: any) => {
        return formNumbers.includes(opt.ref);
      });
      return selected.length;
    },
    requiredEndorsementFormCodes(): string[] {
      const formCodesSet = new Set<string>();

      this.options
        .filter((option: IEndorsement) => {
          const { values } = option;
          return values && values.length;
        })
        .forEach(value => {
          if (value.values?.length) {
            formCodesSet.add(value.formCode);
          }
        });

      const formCodes: string[] = Array.from(formCodesSet);
      return formCodes;
    }
  }
});
