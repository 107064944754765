
import Vue from "vue";
import { authMapGetters } from "@/store/modules/auth";
import { getCompanyLabel } from "../../../../helpers/companies";
export default Vue.extend({
  name: "CustomTerritoryViewer",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    label: {
      type: String,
      required: false,
      default: "Territory Data"
    },
    hideTopBorder: {
      type: Boolean,
      required: false,
      default: false
    },
    companies: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    companyTerritories() {
      const { companyTerritories } = this.value.territoryInfo;

      const result = this.companies.map((company: any) => {
        const findCompany = companyTerritories.find(
          (territory: any) => territory.companyNumber == company.companyNumber
        );
        if (findCompany) {
          return `${getCompanyLabel(
            findCompany.companyNumber,
            findCompany.companyName,
            "quote"
          )} - ${findCompany.terr}`;
        }
      });
      return result;
    },
    seaCoastTerritories() {
      let companies: any = this.companies;
      const { companyTerritories } = this.value.territoryInfo;
      let result: any = [];
      companies.map((company: any) => {
        const findCompany = companyTerritories.find(
          (territory: any) =>
            territory.companyNumber == company.companyNumber &&
            territory.isSeaCoast
        );
        if (findCompany) {
          return result.push(
            `${getCompanyLabel(
              findCompany.companyNumber,
              findCompany.companyName,
              "quote"
            )} - ${findCompany.seaTerr}`
          );
        }
      });
      return result;
    }
  }
});
